import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_cell = _resolveComponent("van-cell")!
  const _component_van_cell_group = _resolveComponent("van-cell-group")!

  return (_openBlock(), _createBlock(_component_van_cell_group, {
    inset: "",
    style: {"margin-top":"10px"}
  }, {
    default: _withCtx(() => [
      _createVNode(_component_van_cell, {
        title: "相关文件",
        icon: "description"
      }),
      _createVNode(_component_van_cell, {
        title: "河南省小微企业工会经费返还申请表",
        icon: "link-o",
        label: "2022/06/14",
        "link-type": "navigateTo",
        url: "https://static.hnlex.com/hlqgh/河南省小微企业工会经费返还申请表.docx"
      }),
      _createVNode(_component_van_cell, {
        title: "关于转发《河南省基层工会经费收支管理实施办法》的通知",
        icon: "link-o",
        label: "2022/06/14",
        "link-type": "navigateTo",
        url: "https://static.hnlex.com/hlqgh/关于转发《河南省基层工会经费收支管理实施办法》的通知.pdf"
      }),
      _createVNode(_component_van_cell, {
        title: "河南省总工会办公室关于转发《中华全国总工会办公厅关于继续实施小微企业工会经费支持政策的通知》的通知",
        icon: "link-o",
        label: "2022/06/14",
        "link-type": "navigateTo",
        url: "https://static.hnlex.com/hlqgh/河南省总工会办公室关于转发《中华全国总工会办公厅关于继续实施小微企业工会经费支持政策的通知》的通知.pdf"
      }),
      _createVNode(_component_van_cell, {
        title: "工会组建咨询办理电话",
        icon: "link-o",
        "link-type": "navigateTo",
        label: "2022/06/14",
        url: "https://static.hnlex.com/hlqgh/组建工会咨询办理电话.docx"
      })
    ]),
    _: 1
  }))
}