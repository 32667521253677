<template>
  <div v-if="!$route.meta.requiresAuth">
    <img
      src="./assets/images/banner2.png"
      class="banner"
      style="width: calc(100% - 20px)"
    />
    <div class="rediusTable">
      <van-row>
        <van-col span="6"
          ><router-link to="/"
            ><van-icon
              name="description"
              size="32"
              color="#16a4fe" /></router-link
        ></van-col>
        <van-col span="6"
          ><router-link to="/go"
            ><van-icon name="refund-o" size="32" color="#16a4fe" /></router-link
        ></van-col>
        <van-col span="6"
          ><a href="https://wenjuan.megawise.cn/appweb/orgScale.html"
            ><van-icon name="smile-comment-o" size="32" color="#16a4fe" /></a
        ></van-col>
        <van-col span="6"
          ><router-link to="/search"
            ><van-icon
              name="todo-list-o"
              size="32"
              color="#16a4fe" /></router-link
        ></van-col>
        <van-col span="6"><router-link to="/">相关文件</router-link></van-col>
        <van-col span="6"
          ><router-link to="/go">工会经费返还</router-link></van-col
        >
        <van-col span="6"
          ><a href="https://wenjuan.megawise.cn/appweb/orgScale.html"
            >中小微企业规模自测</a
          ></van-col
        >
        <van-col span="6"
          ><router-link to="/search">进度查询</router-link></van-col
        >
      </van-row>
    </div>
  </div>
  <router-view />
  <div class="low">
    <div>主办：华龙区总工会</div>
    <div>地址：华龙区政府院内原常委楼5楼</div>
    <div style="margin-top: 10px">技术支持：河南乐信网络科技有限公司</div>
    <div>运维电话：18838963337</div>
    <div style="height: 10px"></div>
  </div>
</template>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.banner {
  border-radius: 10px;
  margin: 10px;
}

.van-cell-group--inset {
  margin: 10px !important;
}

.van-col {
  text-align: center;
}
</style>

<script setup>
var useragent = navigator.userAgent;
if (useragent.match(/MicroMessenger/i) != "MicroMessenger") {
  document.body.innerHTML = "仅允许在微信端访问";
}
</script>
