import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Varlet from "@varlet/ui";
import "@varlet/ui/es/style.js";
// 引入全局css
import "./assets/app.css";
import "animate.css";
import { Toast } from "vant";
import "vant/lib/index.css";
import {
  Form,
  Field,
  Cell,
  CellGroup,
  Button,
  Dialog,
  Tag,
  Col,
  Row,
  Icon,
  Uploader,
  Step,
  Steps,
  RadioGroup,
  Radio,
  Picker,
  Popup,
} from "vant";

createApp(App)
  .use(Varlet)
  .use(Popup)
  .use(RadioGroup)
  .use(Picker)
  .use(Radio)
  .use(Toast)
  .use(Form)
  .use(Field)
  .use(Button)
  .use(Cell)
  .use(CellGroup)
  .use(Dialog)
  .use(Icon)
  .use(Tag)
  .use(Col)
  .use(Row)
  .use(Uploader)
  .use(Step)
  .use(Steps)
  .use(router)
  .mount("#app");
